
import { jwtDecode } from "jwt-decode";
import axios from "axios"


const api_endpoint = "https://4i78mnj9a2.execute-api.ca-central-1.amazonaws.com/prd"


export default async function hit_api(path = "", method = "", body = "") {


  // If token is set in sessionStorage, make sure it is valid
  if (JSON.parse(sessionStorage.getItem("token"))["error"] !== undefined) {
    sessionStorage.removeItem("token")
    window.location.replace(window.location.href)
  }
  else if (jwtDecode(JSON.parse(sessionStorage.getItem("token"))["id_token"])["exp"] <= new Date().getTime() / 1000) {
    sessionStorage.removeItem("token")
    window.location.replace(window.location.href)
  }

  if (method === "POST") {
    return axios
      .post(
        api_endpoint + path,
        body,
        {
          headers: {
            "Authorization": JSON.parse(sessionStorage.getItem("token"))["id_token"],
            "Content-Type": "application/json"
          }
        }
      )
      .then(res => res.data)
  }
}

