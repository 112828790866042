import './style.css';
import Upload from "./Upload"
import Login from "./Login"
import Box from "@mui/material/Box"
import { jwtDecode } from "jwt-decode";



let cognito_domain = "https://pier-upload-cabinet-123141.auth.ca-central-1.amazoncognito.com"
let client_id = "2t1j6dajmnrdodeikt7jg3j1vg"
let host = "https://information.request.mpac.ca/"
// let host = "http://localhost:3000/"

export default function App() {

  let landing_page = <></>

  if (sessionStorage.getItem("token") == null) {
    // console.log("NO TOKEN FOUND IN SESSION STORAGE. LOG IN NOT COMPLETE.")

    // Get authorization code
    let current_url = new URL(document.location)
    let code = current_url.searchParams.get("code")

    if (code != null) {
      // console.log(`AUTHORIZATION CODE FOUND IN URL: ${code}`)
      // console.log(`REQUESTING TOKEN FROM COGNITO`)

      fetch(`${cognito_domain}/oauth2/token?grant_type=authorization_code&client_id=${client_id}&redirect_uri=${host}&code=${code}`, {
        method: "POST",
        headers: {
          "Content-Type": `application/x-www-form-urlencoded`,
        }
      })
        .then(response => response.json())
        .then(json => {
          // console.log(`Received token from AWS: ${json}`)
          sessionStorage.setItem("token", JSON.stringify(json))
          window.location.replace(host);
        })


    } else {
      // console.log("THERE IS NO AUTHORIZATION CODE IN THE URL. RENDERING LOG IN SPLASH")
      landing_page = <Login />
    }



  } else {
    // If token is set in sessionStorage, make sure it is valid
    if (JSON.parse(sessionStorage.getItem("token"))["error"] !== undefined) {
      sessionStorage.removeItem("token")
      window.location.replace(host);
    }
    else if (jwtDecode(JSON.parse(sessionStorage.getItem("token"))["id_token"])["exp"] <= new Date().getTime() / 1000) {
      sessionStorage.removeItem("token")
      window.location.replace(host);
    }

    let token_data = JSON.parse(sessionStorage.getItem("token"))
    sessionStorage.setItem("username", jwtDecode(token_data["id_token"])["email"])

    landing_page = <Upload />
  }







  return (

    <Box sx={{ height: "100vh", width: "100vw" }}>
      {landing_page}
    </Box>
  );
}

