import React from "react";
import axios from "axios";

import Box from "@mui/material/Box"
import MPACLogo from './assets/logo_white.png';
import { Typography } from "@mui/material";

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';



import hit_api from "./Api"

const FormData = require("form-data");

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});








export default function Upload() {

    // Selected file data
    const [selected_file, set_selected_file] = React.useState(null);
    const [uploading_file, set_uploading_file] = React.useState(false);

    const [roll_number, set_roll_number] = React.useState("");
    const [roll_number_invalid, set_roll_number_invalid] = React.useState();
    const [helper_text, set_helper_text] = React.useState("");
    const update_roll_number = (number) => {

        set_roll_number(number)

        if (!isNaN(number)) {
            if (number.length == 15) {
                set_roll_number_invalid(false)
                set_helper_text("")
            } else {
                set_roll_number_invalid(true)
                set_helper_text("Roll number must be exactly 15 characters long")
            }
        } else {
            set_roll_number_invalid(true)
            set_helper_text("Roll number must only contain digits 0-9")
        }


    };

    const [snackbar_open, set_snackbar_open] = React.useState(false);
    const handle_snackbar_close = (event, reason) => {
        set_snackbar_open(false);
    };


    // On file select
    const onFileChange = (event) => {
        set_selected_file(event.target.files[0])
    };


    // On file upload
    const onFileUpload = () => {

        let current_date = new Date()
        let file_date_prefix = current_date.toISOString().split('T')[0]

        let username = "undefined"
        if (sessionStorage.getItem("username") != null) {
            username = sessionStorage.getItem("username")
        }


        let destionation_file_name = selected_file.name.replace(/[^A-Z0-9.]/ig, "")



        // Get presigned URL
        hit_api("/file_upload", "POST", JSON.stringify({ "object_name": `${username}/${file_date_prefix}/${roll_number}/${destionation_file_name}` })).then(function (response) {
            console.log(response)

            // Create form data to submit
            const form = new FormData();
            Object.entries(response["fields"]).forEach(([field, value]) => {
                form.append(field, value);
            });
            form.append("file", selected_file);

            set_uploading_file(true)
            // Post form data
            axios({
                method: "post",
                url: response["url"],
                data: form,
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(function (response) {
                    set_uploading_file(false)

                    if (response.status === 204) {
                        set_snackbar_open(true)
                        set_selected_file(null)
                        update_roll_number("")
                        set_roll_number_invalid()
                        set_helper_text("")
                    } else (
                        alert("Error uploading file")
                    )
                })
                .catch(function (response) {
                    console.log(response);
                });
        })
    };










    // Return file name beside attach button
    const return_file_name = () => {
        if (selected_file != null) {
            return selected_file.name
        } else {
            return "No file selected."
        }
    }



    const return_step_2 = () => {

        if (selected_file != null) {
            return (
                <>
                    <Typography variant="h4">Step 2: Enter Roll Number</Typography>

                    <Box sx={{ marginTop: "auto", height: "50%", width: "100%" }}>

                        <TextField
                            id="outlined-basic"
                            label="Roll number"
                            variant="outlined"
                            value={roll_number}
                            onChange={(event) => {
                                update_roll_number(event.target.value);
                            }}
                            sx={{ width: "50%" }}
                            error={roll_number_invalid}
                            helperText={helper_text}
                        />
                    </Box>
                </>
            )
        } else {
            return <></>
        }
    }


    // Return submit button
    const return_step_3 = () => {
        if (roll_number_invalid == false) {
            return (<>
                <Typography variant="h4">Step 3: Submit Upload</Typography>

                <Box sx={{ marginTop: "auto", height: "50%", width: "100%" }}>
                    <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                        sx={{ backgroundColor: "#005ca6", height: "20%", width: "50%" }}
                        onClick={onFileUpload}
                    >
                        Submit
                    </Button>
                </Box>
            </>
            )
        } else {
            return <></>
        }
    }



    return (

        // Main Container
        <Box sx={{ height: "100%", width: "100%", backgroundColor: "#ffffff" }}>

            {/* Header bar */}
            <Box sx={{ height: "10%", width: "100%", backgroundColor: "#005ca6", color: "white", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img id="app-bar-mpac-logo" src={MPACLogo} alt="mpac logo" style={{ height: "50%" }} />
            </Box>


            {/* 3 tile container */}
            <Box sx={{ height: "90%", width: "calc(100%-64px)", display: "flex", justifyContent: "space-between", padding: "32px" }}>


                {/* Upload box */}
                <Box sx={{ border: "2px solid #005ca6", height: "80%", width: "25%", borderRadius: "10px", padding: "32px", display: "flex", flexDirection: "column", textAlign: "center" }}>
                    <Typography variant="h4">Step 1: Upload File</Typography>

                    <Box sx={{ marginTop: "auto", height: "50%", width: "100%" }}>

                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<AttachFileIcon />}
                            sx={{ backgroundColor: "#005ca6", height: "20%", width: "50%" }}
                        >
                            Attach File
                            <VisuallyHiddenInput type="file" onChange={onFileChange} />
                        </Button>
                        <Typography sx={{ marginTop: "16px" }}>
                            {return_file_name()}
                        </Typography>
                    </Box>
                </Box>



                {/* Roll number */}
                <Box sx={{ border: "2px solid #005ca6", height: "80%", width: "25%", borderRadius: "10px", padding: "32px", display: "flex", flexDirection: "column", textAlign: "center" }}>
                    {return_step_2()}
                </Box>





                {/* Submit button */}
                <Box sx={{ border: "2px solid #005ca6", height: "80%", width: "25%", borderRadius: "10px", padding: "32px", display: "flex", flexDirection: "column", textAlign: "center" }}>
                    {return_step_3()}
                </Box>


            </Box>













            {/* 
            <Box sx={{ height: "20%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography variant="h4">
                    PIER Upload Cabinet
                </Typography>
            </Box>

            <Box sx={{ height: "40%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>

                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<AttachFileIcon />}
                    sx={{ backgroundColor: "#005ca6" }}
                >
                    Attach File
                    <VisuallyHiddenInput type="file" onChange={onFileChange} />
                </Button>
                <Typography sx={{ marginLeft: "16px" }}>
                    {return_file_name()}
                </Typography>


            </Box>


            <Box sx={{ height: "30%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>



                {return_submit_button()}


            </Box> */}






            <Snackbar
                open={snackbar_open}
                onClose={handle_snackbar_close}
                autoHideDuration={6000}
                severity="success"
            >
                <Alert
                    onClose={handle_snackbar_close}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    File uploaded successfully.
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={uploading_file}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}