import React from "react";


import Box from "@mui/material/Box"
import MPACLogo from './assets/logo_white.png';
import { Typography } from "@mui/material";
import Button from '@mui/material/Button';





let cognito_domain = "https://pier-upload-cabinet-123141.auth.ca-central-1.amazoncognito.com"
let client_id = "2t1j6dajmnrdodeikt7jg3j1vg"
let host = "https://information.request.mpac.ca/"
// let host = "http://localhost:3000/"


export default function Upload() {

    return (
        <Box sx={{ height: "100%", width: "100%", backgroundColor: "#ffffff" }}>
            {/* Header bar */}
            <Box sx={{ height: "100%", width: "100%", backgroundColor: "#005ca6", color: "white", display: "flex", alignItems: "center", flexDirection: "column" }}>
                <img id="app-bar-mpac-logo" src={MPACLogo} alt="mpac logo" style={{ width: "30%", marginTop: "128px" }} />
                <Typography variant="h3" sx={{ marginTop: "128px" }}>Assessment Information Request Program Upload Cabinet</Typography>

                <Button id="sign-in-button" variant="contained" size="large" sx={{ marginTop: "128px", backgroundColor: "white", color: "#005ca6", fontSize: "1.5em" }} onClick={() => {
                    window.location.replace(`${cognito_domain}/oauth2/authorize?response_type=code&client_id=${client_id}&redirect_uri=${host}`)
                }}>
                    Sign in
                </Button>
            </Box>

        </Box>
    )
}